export function navBarData(data) {
  const leftMenuData = [
    {
      id: "1",
      name: "Business Services",
      short_des: "We specialise in providing services to businesses",
      link: "/business-services",
      children: [
        {
          name: "Overview",
          link: "/business-services",
        },
        {
          name: "Business Tax Returns",
          link: "/business-services/business-tax-returns",
        },
        {
          name: "Business Structuring",
          link: "/business-services/business-structuring",
        },
        {
          name: "Business Advisory",
          link: "/business-services/business-advisory",
        },
        {
          name: "Bookkeeping",
          link: "/business-services/bookkeeping",
        },
        {
          name: "Business Activity Statements",
          link: "/business-services/business-activity-statements",
        },
        {
          name: "Payroll Services",
          link: "/business-services/payroll",
        },
        {
          name: "Professional Firm Profits",
          link: "/business-services/professional-firm-profits",
        },
        {
          name: "Business Startups",
          link: "/business-services/business-startups",
        },
        {
          name: "Fringe Benefits Tax (FBT)",
          link: "/business-services/fringe-benefits-tax",
        },
        {
          name: "Small Business Entity Concessions",
          link: "/business-services/small-business-entity-concessions",
        },
        {
          name: "Taxable Payment Annual Summary Reports",
          link: "/business-services/taxable-payments-annual-summary-reports",
        },
        {
          name: "Company Tax Returns",
          link: "/business-services/company-tax-returns",
        },
        {
          name: "Trust Tax Returns",
          link: "/business-services/trust-tax-returns",
        },
        {
          name: "Sole Trader Tax Returns",
          link: "/business-services/sole-trader-tax-returns",
        },
        {
          name: "Partnership Tax Returns",
          link: "/business-services/partnership-tax-returns",
        },
        {
          name: "What’s the best way to pay super",
          link: "/business-services/best-way-to-pay-super",
        },
        {
          name: "Do I need to register an ABN?",
          link: "/business-services/register-an-abn",
        },
        {
          name: "How do business names work?",
          link: "/business-services/business-names",
        },
        {
          name: "About Fuel Tax Credits",
          link: "/business-services/fuel-tax-credits",
        },
        {
          name: "About PAYG Withholding",
          link: "/business-services/register-payg-withholding",
        },
        {
          name: "Details around Workcover",
          link: "/business-services/workcover-details",
        },
        {
          name: "Portable Long Service Leave",
          link: "/business-services/long-service-leave",
        },
        {
          name: "Single Touch Payroll",
          link: "/business-services/single-touch-payroll",
        },
        {
          name: "How to create a valid invoice",
          link: "/business-services/create-a-valid-invoice",
        },
      ],
    },
    {
      id: "2",
      name: "SMSF",
      short_des: "Services for people who run SMSFs",
      link: "/self-managed-superannuation-funds",
      children: [
        {
          name: "Overview",
          link: "/self-managed-superannuation-funds",
        },
        {
          name: "Annual Requirements",
          link: "/smsf-services/annual-requirements",
        },
        {
          name: "SMSF Tax Returns",
          link: "/smsf-services/smsf-tax-returns",
        },
        {
          name: "How to start a SMSF",
          link: "/smsf-services/how-to-start-a-smsf",
        },
        {
          name: "SMSF Estate Planning",
          link: "/smsf-services/smsf-estate-planning",
        },
        {
          name: "Investment Options",
          link: "/smsf-services/investment-options",
        },
        {
          name: "Conditions of release",
          link: "/smsf-services/conditions-of-release",
        },
        {
          name: "Buying property through a SMSF",
          link: "/smsf-services/buying-property-through-smsf",
        },
        {
          name: "Key features of a SMSF",
          link: "/smsf-services/smsf-key-features",
        },
        {
          name: "Holding Insurance",
          link: "/smsf-services/holding-insurance",
        },
        {
          name: "Moving to pension phase",
          link: "/smsf-services/moving-pension-phase",
        },
        {
          name: "Claiming a deduction for contributions",
          link: "/smsf-services/claiming-deduction-for-contributions",
        },
        {
          name: "Audit Preparation",
          link: "/smsf-services/audit-preparation",
        },
      ],
    },
    {
      id: "3",
      name: "Client Portal",
      short_des: "Secure access to your financial records",
      link: "",
      children: [
        {
          name: "Old Reynella Client Portal",
          link: "https://mayaccountingtaxation.portal.accountants/login",
        },
        {
          name: "Morphett Vale Client Portal",
          link: "https://documenthub.handisoft.com.au/ClientPortal/Account/Login/DVMA0001",
        },
      ],
    },
    {
      id: "4",
      name: "Individual Tax",
      short_des: "Employee, Investors & other tax services",
      link: "/individual-tax-return-services",
      children: [
        {
          name: "Overview",
          link: "/individual-tax-return-services",
        },
        {
          name: "Catch-Up on Past Returns",
          link: "/individual-tax-return-services/tax-catch-ups",
        },
        {
          name: "Rental Property Tax Returns",
          link: "/individual-tax-return-services/rental-property-tax-returns",
        },
        {
          name: "Capital Gains Tax - Individuals",
          link: "/individual-tax-return-services/capital-gains-tax",
        },
        {
          name: "Tax Planning - Individuals",
          link: "/individual-tax-return-services/tax-planning",
        },
        {
          name: "Tax & Crypto Currency",
          link: "/individual-tax-return-services/tax-and-crypto-currency",
        },
        {
          name: "Work related use of a car",
          link: "/individual-tax-return-services/work-related-car-use",
        },
        {
          name: "How to keep a valid logbook",
          link: "/individual-tax-return-services/how-to-keep-valid-logbook",
        },
        {
          name: "Deceased Estates",
          link: "/individual-tax-return-services/deceased-estates",
        },
        {
          name: "Record keeping requirements",
          link: "/individual-tax-return-services/record-keeping-requirements-individuals",
        },
      ],
    },
    {
      id: "5",
      name: "Industry Types",
      short_des: "Information for different industry types",
      link: "/industry-types",
      children: [
        {
          name: "Overview",
          link: "/industry-types",
        },
        {
          name: "Agribusiness Accountant",
          link: "/industry-types/agribusiness-accountant",
        },
        {
          name: "Family Business Accountant",
          link: "/industry-types/family-business-accountant",
        },
        {
          name: "Hospitality Accountant",
          link: "/industry-types/hospitality-accountant",
        },
        {
          name: "Pharmacy Accountant",
          link: "/industry-types/pharmacy-accountant",
        },
        {
          name: "Professional Practice Accountant",
          link: "/industry-types/professional-practice-accountant",
        },
        {
          name: "Trades and Construction Accountant",
          link: "/industry-types/trades-and-construction-accountant",
        },
      ],
    },
    {
      id: "6",
      name: "Book Online",
      short_des: "Click here to book your consult now",
      link: "https://dvm-accounting---morphett-vale.square.site",
      children: [],
    },
  ];
  return leftMenuData;
}

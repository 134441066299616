import React from "react";
import { useRouter } from "../../components";
import { SiteLink } from "../../components";

export function BottomDock(props) {
  const links = [
    {
      url: "/our-people",
      title: "OUR PEOPLE",
      icon: "about-us.png",
      iconHover: "about-us.png",
    },
    {
      url: "/individual-tax-return-services",
      title: "TAXES",
      icon: "tax-services.png",
      iconHover: "tax-services.png",
    },
    {
      url: "/business-services",
      title: "BUSINESS",
      icon: "business-services.png",
      iconHover: "business-services.png",
    },
    {
      url: "/industry-types",
      title: "INDUSTRY",
      icon: "industry-types.png",
      iconHover: "industry-types.png",
    },
    {
      url: "/self-managed-superannuation-funds",
      title: "SUPER FUND",
      icon: "superannuation.png",
      iconHover: "superannuation.png",
    },
  ];

  //const path = usePath();
  const { pathname } = useRouter();

  const isCurrent = (link) => {
    return pathname && pathname.toLowerCase() === link.toLowerCase();
  };

  const linkElts = links.map((link, index) => (
    <li
      key={index}
      className={`bottomMenu__li ${isCurrent(link.url) ? "selected" : ""}`}
    >
      <SiteLink
        to={link.url}
        className={`bottomMenu__link bottomMenu__link--${index} ${
          isCurrent(link.url) ? "selected" : "unselected"
        }`}
      >
        <div className='icon'>
          <img
            className='normal'
            src={`/assets/navicons/white/${link.icon}`}
            alt={link.title}
          />
          <img
            className='selected'
            src={`/assets/navicons/green/${link.iconHover}`}
            alt={link.title}
          />
        </div>
        <div>{link.title}</div>
      </SiteLink>
    </li>
  ));

  return (
    <>
      <nav className='bottomMenu'>
        <ul className='bottomMenu__links'>{linkElts}</ul>
      </nav>
    </>
  );
}

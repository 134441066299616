import React from "react";
import cmsUtils from "../../utils/cmsUtils";
import utils from "../../../utils";
import { SiteLink } from "../../../components";

import Slider from "react-slick";

export function PageTiles(props) {
  const item = props.item;
  const cmsOption = props.cmsOption || {};
  //console.log('item', item);
  const useSlider = cmsUtils.payload(item, "UseSlider") === "slider";
  const pages = getPages(cmsUtils.payload(item, "PageObjects"));
  //console.log(pages);
  const pageTileElts = pages.map((page, index) => (
    <Tile page={page} key={index} disableLink={cmsOption.isCmsEdit}></Tile>
  ));

  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    autoplaySpeed: 3000,
    fade: false,
    autoplay: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      // You can unslick at a given breakpoint now by adding:
      // settings: "unslick"
      // instead of a settings object
    ],
  };

  const cssClass = utils.classNames(
    "cms_item",
    "pageTiles pageTiles_small tiles",
    useSlider && "useSlider",
    item.cssClass || item.anchorName || ""
  );

  if (useSlider) {
    return (
      <div
        className={cssClass}
        data-cms-item-id={cmsOption.isCmsEdit && item.itemId}
        id={item.anchorName}
      >
        <Slider {...settings}>{pageTileElts}</Slider>
      </div>
    );
  } else {
    return (
      <div
        className={cssClass}
        data-cms-item-id={cmsOption.isCmsEdit && item.itemId}
        id={item.anchorName}
      >
        {pageTileElts}
      </div>
    );
  }
}

function getPages(pagesSerialized) {
  if (pagesSerialized) {
    return JSON.parse(pagesSerialized);
  }
  return [];
}

function Tile(props) {
  const page = props.page;
  const imageUrl = utils.site.resourcePath(page.imageUrl);
  const disableLink = props.disableLink;
  return (
    <div className='pageTile tile'>
      <SiteLink
        className='pageTile__content tile__content'
        to={disableLink ? "" : page.pageUrl}
      >
        <div
          className='pageTile_small_content'
          style={{ backgroundImage: utils.css.bgUrlStyle(imageUrl) }}
        >
          <div className='pageTile__title'>
            {page.pageTitle}
            <svg
              height='22px'
              width='22px'
              id='Layer_1'
              xmlns='http://www.w3.org/2000/svg'
              xmlnsXlink='http://www.w3.org/1999/xlink'
              viewBox='0 0 50 50'
              enableBackground='new 0 0 50 50'
              xmlSpace='preserve'
              {...props}
            >
              <path
                fill='#fff'
                stroke='white'
                stroke-width='5'
                d='M15.563,40.836c0.195,0.195,0.451,0.293,0.707,0.293s0.512-0.098,0.707-0.293l15-15 c0.391-0.391,0.391-1.023,0-1.414l-15-15c-0.391-0.391-1.023-0.391-1.414,0s-0.391,1.023,0,1.414l14.293,14.293L15.563,39.422 C15.172,39.813,15.172,40.446,15.563,40.836z'
              />
            </svg>
          </div>
          <div className='pageTile_desc_container'>
            <div className='pageTile_desc'>
              {page.tileDesc || page.description}
            </div>
            <div className='pageTile__bsyc__button'>Read More</div>
          </div>
        </div>
      </SiteLink>
    </div>
  );
}

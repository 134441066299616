import React, { useState, useEffect } from "react";
import { SiteLink } from "../../components";
import { navBarData } from "./navBarData";

export function NavBarDesktop(props) {
  const open = props.open;
  const [scrolled, setScrolled] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [selectedItemId, setSelectedItemId] = useState(null);
  const [activeItemId, setActiveItemId] = useState(null);
  const model = navBarData({});

  useEffect(() => {
    if (props.selectedItemId !== selectedItemId) {
      if (props.selectedItemId) {
        const item = model.find((item) => item.id === props.selectedItemId);
        if (item) {
          setSelectedItem(item);
          setSelectedItemId(props.selectedItemId);
          setActiveItemId(props.selectedItemId);
        }
      } else {
        setSelectedItem(null);
        setSelectedItemId(null);
        setActiveItemId(null);
      }
    }
  }, [props.selectedItemId, model, selectedItemId]);

  useEffect(() => {
    if (!props.open) {
      setSelectedItem(null);
      setActiveItemId(null);
      setSelectedItemId(null);
    }
  }, [props.open]);

  useEffect(() => {
    const onScroll = () => {
      if (window.scrollY > 50) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };
    window.addEventListener("scroll", onScroll);
    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  const handleItemClick = (item, id) => {
    if (id === "6") {
      setSelectedItem(null);
      setSelectedItemId(id);
      setActiveItemId(id);
    } else {
      setSelectedItem(item);
      setSelectedItemId(id);
      setActiveItemId(id);
    }
    props.onItemSelect(id);
  };

  function closeMenu() {
    setActiveItemId(null);
    setSelectedItemId(null);
    props.onCloseMenu();
  }

  return (
    <div
      className={`navBar navBarDesktop ${scrolled ? "navBarScrolled" : ""} ${
        open ? "navBar--on navBarDesktop--on" : ""
      }`}
      id='sliding-menu'
    >
      <div
        className='navBar__scrollArea navBar_desktop_scrollArea'
        id='top-menu'
      >
        <nav className='navBar_nav navBar_desktop_nav'>
          <div className='left_navBar'>
            {model.map((item, index) => (
              <div key={index}>
                {index === 5 ? (
                  <div
                    className={`${
                      activeItemId === item.id
                        ? "left_item_selected"
                        : "left_item"
                    }`}
                    onClick={() => handleItemClick(item, item.id)}
                  >
                    <a
                      href='https://dvm-accounting---morphett-vale.square.site'
                      target='blank'
                      className='left_item_wrap'
                    >
                      <div className='left_item_name'>{item.name}</div>
                      <div className='left_item_des'>{item.short_des}</div>
                    </a>
                  </div>
                ) : (
                  <div
                    className={`${
                      selectedItemId === item.id
                        ? "left_item_selected"
                        : "left_item"
                    }`}
                    onClick={() => handleItemClick(item, item.id)}
                  >
                    <div className='left_item_name'>{item.name}</div>
                    <div className='left_item_des'>{item.short_des}</div>
                  </div>
                )}
              </div>
            ))}
          </div>
          <div
            className={`right_navBar ${
              selectedItem ? "right_navBar_updated" : ""
            }`}
          >
            {selectedItem && (
              <div className='right_item_content'>
                {selectedItem.id === "3" ? (
                  <div
                    className='right_content_name right_content_name3'
                    onClick={() => closeMenu()}
                  >
                    {selectedItem.name}
                  </div>
                ) : (
                  <SiteLink
                    to={selectedItem.link}
                    className='right_content_name'
                    onClick={() => closeMenu()}
                  >
                    {selectedItem.name}
                  </SiteLink>
                )}
                <div className='line'></div>
                <div className='right_content_links'>
                  {selectedItem.children.map((subItem, subIndex) => (
                    <SiteLink
                      key={subIndex}
                      to={subItem.link}
                      className='right_content_link'
                      onClick={() => closeMenu()}
                    >
                      {subItem.name}
                    </SiteLink>
                  ))}
                </div>
              </div>
            )}
            {!selectedItem && (
              <>
                <div className='right_item'>
                  <SiteLink
                    to='/'
                    className='right_item_name'
                    onClick={() => closeMenu()}
                  >
                    Home
                  </SiteLink>
                  <div className='line'></div>
                  <div className='right_item_des'>
                    Our mission and driving force
                  </div>
                </div>
                <div className='right_item'>
                  <SiteLink
                    to='/our-people'
                    className='right_item_name'
                    onClick={() => closeMenu()}
                  >
                    Our People
                  </SiteLink>
                  <div className='line'></div>
                  <div className='right_item_des'>
                    Get to know us a little better
                  </div>
                </div>
                <div className='right_item'>
                  <SiteLink
                    to='/contact-us'
                    className='right_item_name'
                    onClick={() => closeMenu()}
                  >
                    Contact Us
                  </SiteLink>
                  <div className='line'></div>
                  <div className='right_item_des'>
                    Speak to one of our friendly staff today
                  </div>
                </div>
              </>
            )}
          </div>
        </nav>
      </div>
    </div>
  );
}

import { css } from "@emotion/core";
import { mq } from "../../cssInJs";

export default {
  top: css(
    mq({
      height: [44, null, 74],
      background: "#fff",
      position: "fixed",
      width: "100%",
      zIndex: "999",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      transition: "all 0.3s",
    })
  ),
  topScrolled: css(
    mq({
      height: [44, null, 64],
    })
  ),
  logo: css(mq({})),
  logoImg: css(
    mq({
      transition: "all 0.3s",
      display: "block",
      height: [30, null, 55],
    })
  ),
  logoImgScolled: css(
    mq({
      transition: "all 0.3s",
      display: "block",
      height: [30, null, 45],
    })
  ),
};

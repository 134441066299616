import React, { useState } from "react";
import cmsUtils from "../../utils/cmsUtils";
import utils from "../../../utils";
import { SiteLink } from "components";

export function AccordionPanel(props) {
  const item = props.item;
  const subItems = item.items || [];
  const cmsOption = props.cmsOption || {};
  const bgColor = cmsUtils.payload(item, "BgColor");
  const imageUrl = cmsUtils.payload(item, "ImageUrl");
  const backgroundTile = cmsUtils.payload(item, "BackgroundTile");

  const [selected, setSelected] = useState(null);
  const toggle = (i) => {
    if (selected === i) {
      return setSelected(null);
    }
    setSelected(i);
  };

  let bgUrl = null;
  if (imageUrl) bgUrl = utils.site.resourcePath(imageUrl);
  else if (backgroundTile)
    bgUrl = utils.site.resourcePath(
      `/dist/transparent-textures/textures/${backgroundTile}`
    );
  const cssClass = utils.classNames(
    "cms_item",
    "accordionPanel",
    "contentPanel--",
    item.cssClass || item.anchorName || ""
  );

  const subItemsElts = subItems.map((subItem, index) => (
    <div className='wrapper' key={index}>
      <div className={selected === index ? "accordion show" : "accordion"}>
        <div className='item'>
          <div
            className={selected === index ? "title show" : "title"}
            onClick={() => toggle(index)}
          >
            <h4>{subItem.payloads[0].value}</h4>
            <span className='open_button'></span>
          </div>
          <div className={selected === index ? "content show" : "content"}>
            <div className='content_text'>
              <ParsedString inputString={subItem.payloads[1].value} />
            </div>
          </div>
        </div>
      </div>
    </div>
  ));

  return (
    <>
      <section
        className={cssClass}
        style={{
          backgroundColor: `${bgColor}`,
          backgroundImage: bgUrl && utils.css.bgUrlStyle(bgUrl),
        }}
        data-cms-item-id={cmsOption.isCmsEdit && item.itemId}
        id={item.anchorName}
      >
        <div className='container'>
          <div className='accordion_container'>{subItemsElts}</div>
        </div>
      </section>
    </>
  );
}

function parseString(inputString) {
  const result = [];

  // Helper function to process bold text
  const processBoldText = (text) => {
    if (typeof text !== "string") return text;
    const boldParts = text.split(/(<b>.*?<\/b>)/g);
    return boldParts.map((part, index) => {
      if (part.startsWith("<b>") && part.endsWith("</b>")) {
        const content = part.replace(/<\/?b>/g, "");
        return <b key={`bold-${index}`}>{content}</b>;
      }
      return part;
    });
  };

  // Helper function to process links
  const processLinks = (content) => {
    if (typeof content !== "string") return content;
    const linkParts = content.split(/({[^}]+})/g);

    return linkParts.map((part, linkIndex) => {
      if (part.startsWith("{") && part.endsWith("}")) {
        const linkObject = {};
        const pairs = part.slice(1, -1).split(/,(?=\s*\w+:)/);
        pairs.forEach((pair) => {
          const colonIndex = pair.indexOf(":");
          if (colonIndex !== -1) {
            const key = pair.slice(0, colonIndex).trim();
            const value = pair
              .slice(colonIndex + 1)
              .trim()
              .replace(/^"|"$/g, "");
            linkObject[key] = value;
          }
        });

        const isAbsolute =
          (linkObject.link || linkObject.url || "").startsWith("http") ||
          (linkObject.link || linkObject.url || "").startsWith("https");

        return isAbsolute ? (
          <a
            key={`link-${linkIndex}`}
            href={linkObject.link || linkObject.url}
            target='_blank'
            rel='noopener noreferrer'
          >
            {linkObject.title || "Link"}
          </a>
        ) : (
          <SiteLink
            key={`link-${linkIndex}`}
            to={linkObject.link || linkObject.url}
          >
            {linkObject.title || "Link"}
          </SiteLink>
        );
      }
      return part;
    });
  };

  // Helper function to process text with both bold and links
  const processTextContent = (text) => {
    const boldProcessed = processBoldText(text);
    if (Array.isArray(boldProcessed)) {
      return boldProcessed.map(processLinks).flat();
    }
    return processLinks(boldProcessed);
  };

  // Process paragraphs and lists
  const parts = inputString.split(
    /(<p>.*?<\/p>|\(\*[\s\S]+?\)|\(#[\s\S]+?\))/g
  );

  parts.forEach((part, index) => {
    if (part.startsWith("<p>") && part.endsWith("</p>")) {
      // Process paragraph
      const content = part.replace(/<\/?p>/g, "").trim();
      result.push(
        <p key={`paragraph-${index}`}>{processTextContent(content)}</p>
      );
    } else if (part.startsWith("(*") && part.endsWith(")")) {
      // Process unordered list
      const listItems = part
        .slice(2, -1)
        .split("*")
        .map((item) => item.trim())
        .filter(Boolean);
      result.push(
        <ul key={`ul-${index}`}>
          {listItems.map((item, itemIndex) => (
            <li key={itemIndex}>{processTextContent(item)}</li>
          ))}
        </ul>
      );
    } else if (part.startsWith("(#") && part.endsWith(")")) {
      // Process ordered list
      const listItems = part
        .slice(2, -1)
        .split("#")
        .map((item) => item.trim())
        .filter(Boolean);
      result.push(
        <ol key={`ol-${index}`}>
          {listItems.map((item, itemIndex) => (
            <li key={itemIndex}>{processTextContent(item)}</li>
          ))}
        </ol>
      );
    } else if (part.trim()) {
      // Add any other text
      result.push(processTextContent(part));
    }
  });

  return result.flat();
}

function ParsedString({ inputString }) {
  const parsedContent = parseString(inputString);
  return <>{parsedContent}</>;
}

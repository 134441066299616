/** @jsx jsx */
import { jsx } from "@emotion/core";
import { SiteLink } from "../../components";
import { FaAngleLeft } from "react-icons/fa";
import { useHistoryStore } from "../../components";
import style from "./backButtonStyle";

export function BackButton(props) {
  const history = useHistoryStore();

  function goBack(e) {
    history.pop();
  }

  if (history.last()) {
    return (
      <SiteLink
        className='back_button'
        css={style.button}
        title='Go Back'
        to={history.last()}
        onClick={goBack}
      >
        <FaAngleLeft />
      </SiteLink>
    );
  } else {
    return null;
  }
}

/** @jsx jsx */
import { jsx } from "@emotion/core";
import { useRouter } from "./../../components";
import { useState, Fragment, useEffect } from "react";
import { SiteLink } from "../../components";
import { useScrollPosition } from "@n8tb1t/use-scroll-position";
import { NavBarDesktop } from "./navBarDesktop";
import { NavBarMobile } from "./navBarMobile";
import { BackButton } from "../backButton/backButton";
import { Hamburger } from "../hamburger/hamburger";
import style from "./topHeaderStyle";
import utils from "../../utils";

export function TopHeader(props) {
  const [menuOpened, setMenuOpened] = useState(false);
  const [selectedNavItem, setSelectedNavItem] = useState(null);
  const [headerCopy, setHeaderCopy] = useState("");
  const [scrolled, setScrolled] = useState(false);
  const isMobile = utils.site.isMobile();
  const { pathname } = useRouter();
  useEffect(() => {
    if (
      pathname === "/" ||
      pathname === "/our-people" ||
      pathname === "/contact-us" ||
      pathname === "/business-services" ||
      pathname === "/self-managed-superannuation-funds" ||
      pathname === "/individual-tax-return-services" ||
      pathname === "/industry-types"
    ) {
      setHeaderCopy("Taxation, Business Services, Self-Managed Super");
    } else {
      setHeaderCopy("Concise article series from DVM Accounting & Taxation");
    }
  }, [pathname]);

  useScrollPosition(
    ({ prevPos, currPos }) => {
      //console.log(currPos.x)
      //console.log(currPos.y);
      if (currPos.y > 90) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
      //setScrolled(currPos.y > 90)
    },
    [],
    null,
    true
  );

  function toggleMenu(e) {
    if (menuOpened) {
      setSelectedNavItem(null);
    }
    setMenuOpened(!menuOpened);
  }

  function onCloseMenu() {
    setMenuOpened(false);
    setSelectedNavItem(null);
  }

  function handleClientPortalClick(e) {
    e.preventDefault();
    setMenuOpened(true);
    setSelectedNavItem("3");
  }

  const handleNavItemSelect = (itemId) => {
    setSelectedNavItem(itemId);
  };

  function backTop() {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }

  useEffect(() => {
    const backButton = document.querySelector(".back_button");
    if (backButton) {
      if (menuOpened) {
        backButton.classList.add("back_button_styled");
      } else {
        backButton.classList.remove("back_button_styled");
      }
    }
  }, [menuOpened]);

  if (isMobile) {
    return (
      <Fragment>
        <header css={[style.top, scrolled && style.topScrolled]} id='top'>
          <BackButton />
          <SiteLink
            to='/'
            css={[style.logo, scrolled && style.logoScrolled]}
            onClick={backTop}
            className='topHeaderLogo'
          >
            <img
              css={[style.logoImg, scrolled && style.logoImgScolled]}
              src='/assets/logos/logo.png'
              alt='logo'
            />
          </SiteLink>
          <div className='topLinks'>
            <SiteLink to='/'>
              <span>Client Portal</span>
            </SiteLink>
            <SiteLink to='https://dvm-accounting---morphett-vale.square.site'>
              <span>Book Online</span>
            </SiteLink>
            <a href='tel:0883224110'>(08) 8322 4110</a>
          </div>
          <Hamburger
            opened={menuOpened}
            toggleMenu={toggleMenu}
            scrolled={scrolled}
          />
        </header>
        <NavBarMobile
          open={menuOpened}
          onCloseMenu={onCloseMenu}
        ></NavBarMobile>
        <div
          className={`topHeaderCopy ${scrolled ? "topHeaderCopyScrolled" : ""}`}
        >
          {headerCopy}
        </div>
        <div
          className={`topHeaderLine ${scrolled ? "topHeaderLineScrolled" : ""}`}
        ></div>
      </Fragment>
    );
  } else {
    return (
      <Fragment>
        <header css={[style.top, scrolled && style.topScrolled]} id='top'>
          <BackButton />
          <SiteLink
            to='/'
            css={[style.logo, scrolled && style.logoScrolled]}
            onClick={backTop}
            className='topHeaderLogo'
          >
            <img
              css={[style.logoImg, scrolled && style.logoImgScolled]}
              src='/assets/logos/logo.png'
              alt='logo'
            />
          </SiteLink>
          <div className='topLinks'>
            <SiteLink to='/' onClick={handleClientPortalClick}>
              <span>Client Portal</span>
            </SiteLink>
            <SiteLink to='https://dvm-accounting---morphett-vale.square.site'>
              <span>Book Online</span>
            </SiteLink>
            <a href='tel:0883224110'>(08) 8322 4110</a>
          </div>
          <Hamburger
            opened={menuOpened}
            toggleMenu={toggleMenu}
            scrolled={scrolled}
          />
        </header>
        <NavBarDesktop
          open={menuOpened}
          onCloseMenu={onCloseMenu}
          selectedItemId={selectedNavItem}
          onItemSelect={handleNavItemSelect}
        ></NavBarDesktop>
        <div
          className={`topHeaderCopy ${scrolled ? "topHeaderCopyScrolled" : ""}`}
        >
          {headerCopy}
        </div>
        <div
          className={`topHeaderLine ${scrolled ? "topHeaderLineScrolled" : ""}`}
        ></div>
      </Fragment>
    );
  }
}

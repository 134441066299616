import { css } from "@emotion/core";
import { mq } from "../../../cssInJs";

export default {
  pageTiles: css(
    mq({
      display: "flex",
      justifyContent: "flex-start",
      flexWrap: "wrap",
      margin: ["2rem -10px", null, null, null, "2rem -10px", "2rem -90px"],
      overflow: "hidden",
    })
  ),
  pageTile__tile: css(
    mq({
      flex: "0 0 100%",
      maxWidth: "100%",
      padding: "10px",
      "&:nth-of-type(even)": {
        ".pageTile__bsyc__bg": mq({
          order: ["0", null, "2"],
        }),
        ".pageTile__bsyc__content__details": mq({
          order: "1",
        }),
      },
    })
  ),

  tile__content: css({
    "&:hover": {
      textDecoration: "none",
      "& .pageTile__bsyc__content__details": {
        color: "white",
      },
      "& .pageTile__bsyc__bg2": {
        transform: "scale(1.05)",
      },
      "& .pageTile__bsyc__button": {
        color: "#063051",
        background: "#fff",
        border: "1px solid #fff",
        textDecoration: "none",
      },
    },
  }),

  tile__flip__wrapper: css({
    zIndex: "-1",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
    height: "100%",
    cursor: "pointer",
    position: "relative",
    flexWrap: "wrap",
  }),

  tile__bg: css(
    mq({
      height: "0",
      paddingTop: ["60%", "35%", "30%"],
      flex: "1 1 300px",
      overflow: "hidden",
      position: "relative",
    })
  ),

  tile__bg2: css(
    mq({
      height: "100%",
      backgroundPosition: "50%",
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      transition: "all .2s",
      position: "absolute",
      top: "0px",
      width: "100%",
    })
  ),

  tile__details: css({
    overflow: "hidden",
    flexGrow: "1",
    textAlign: "center",
    width: "100%",
    bottom: "0",
    flex: "1 1 300px",
    transition: "all .2s ease-in-out",
    color: "#ebebeb",
    position: "relative",
    "& .pageTile__bsyc__container": mq({
      position: ["static,", null, "absolute"],
      top: "50%",
      left: "50%",
      transform: ["none", null, "translate(-50%, -50%)"],
      width: ["100%", null, "77%", "80%"],
      padding: ["1.5rem", null, "0px"],
      textAlign: "left",
      "& .pageTile__bsyc__title": {
        fontSize: ["1.5rem", null, "1.5rem", "2.2rem"],
        fontWeight: "500",
        lineHeight: "1",
        margin: "0",
        paddingBottom: "1rem",
        color: "#fff",
      },
      "& .pageTile__bsyc__desc": {
        lineHeight: "1.5",
        fontSize: [".9rem", ".7rem", ".7rem", ".7rem", ".9rem"],
        minHeight: "70px",
        color: "#fff",
      },
    }),
  }),
};

import React from "react";
import cmsUtils from "../../utils/cmsUtils";
import utils from "../../../utils";
import { SiteLink } from "../../../components";
import Slider from "react-slick";

export function PageTiles2(props) {
  const item = props.item;
  const cmsOption = props.cmsOption || {};
  const useSlider = cmsUtils.payload(item, "UseSlider") === "slider";
  const pages = getPages(cmsUtils.payload(item, "PageObjects"));
  const pageTileElts = pages.map((page, index) => (
    <Tile page={page} key={index} disableLink={cmsOption.isCmsEdit}></Tile>
  ));

  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    autoplaySpeed: 3000,
    fade: false,
    autoplay: false,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const cssClass = utils.classNames(
    "cms_item",
    "pageTiles pageTiles_small pageTiles_small2 tiles",
    useSlider && "useSlider",
    item.cssClass || item.anchorName || ""
  );

  if (useSlider) {
    return (
      <div
        className={cssClass}
        data-cms-item-id={cmsOption.isCmsEdit && item.itemId}
        id={item.anchorName}
      >
        <Slider {...settings}>{pageTileElts}</Slider>
      </div>
    );
  } else {
    return (
      <div
        className={cssClass}
        data-cms-item-id={cmsOption.isCmsEdit && item.itemId}
        id={item.anchorName}
      >
        {pageTileElts}
      </div>
    );
  }
}

function getPages(pagesSerialized) {
  if (pagesSerialized) {
    return JSON.parse(pagesSerialized);
  }
  return [];
}

function Tile(props) {
  const page = props.page;
  const imageUrl = utils.site.resourcePath(page.imageUrl);
  const disableLink = props.disableLink;
  return (
    <div className='pageTile tile'>
      <SiteLink
        className='pageTile__content pageTile2__content tile__content'
        to={disableLink ? "" : page.pageUrl}
      >
        <div
          className='pageTile_small2_bg'
          style={{ backgroundImage: utils.css.bgUrlStyle(imageUrl) }}
        ></div>
        <div className='pageTile_small2_content'>
          <div className='pageTile2__title'>{page.pageTitle}</div>
          <div className='pageTile2__desc'>
            {page.tileDesc || page.description}
          </div>
        </div>
      </SiteLink>
    </div>
  );
}

import React, { useState, useEffect } from "react";
import { SiteLink } from "../../components";
import { navBarData } from "./navBarDataMobile";
import { FaAngleLeft } from "react-icons/fa";

export function NavBarMobile(props) {
  const open = props.open;
  const [scrolled, setScrolled] = useState(false);
  const [activeIndex, setActiveIndex] = useState(null);
  const model = navBarData({});

  const clientPortalItem = {
    id: "clientPortal",
    name: "Client Portal",
    short_des: "Secure access to your financial records",
    link: "",
    children: [
      {
        name: "Old Reynella Client Portal",
        link: "https://mayaccountingtaxation.portal.accountants/login",
      },
      {
        name: "Morphett Vale Client Portal",
        link: "https://documenthub.handisoft.com.au/ClientPortal/Account/Login/DVMA0001",
      },
    ],
  };

  const toggleNavChildren = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const toggleClientPortal = () => {
    setActiveIndex(activeIndex === "clientPortal" ? null : "clientPortal");
  };

  useEffect(() => {
    if (!props.open) {
      setActiveIndex(null);
    }
  }, [props.open]);

  useEffect(() => {
    const onScroll = () => {
      if (window.scrollY > 50) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };
    window.addEventListener("scroll", onScroll);
    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  function closeMenu() {
    props.onCloseMenu();
    setActiveIndex(null);
  }

  return (
    <div
      className={`navBar navBarMobile ${scrolled ? "navBarScrolled" : ""} ${
        open ? "navBar--on" : ""
      }`}
      id='sliding-menu'
    >
      <div
        className='navBar__scrollArea navBar_mobile_scrollArea'
        id='top-menu'
      >
        <nav className='navBar_nav navBar_mobile_nav'>
          <div className='nav_parent_wrapper'>
            {activeIndex === null ? (
              model.map((item, index) => (
                <div key={index} className='nav_parent_item'>
                  {index === 4 || index === 5 ? (
                    <div className='nav_parent_name'>
                      <SiteLink to={item.link} onClick={() => closeMenu()}>
                        {item.name}
                      </SiteLink>
                    </div>
                  ) : (
                    <div
                      className='nav_parent_name'
                      onClick={() => toggleNavChildren(index)}
                    >
                      {item.name}
                    </div>
                  )}
                </div>
              ))
            ) : (
              <div className='nav_children_wrapper'>
                <div
                  className='nav_back_button'
                  onClick={() => setActiveIndex(null)}
                >
                  <FaAngleLeft />
                  Back
                </div>
                <div className='nav_parent_name_inside'>
                  {activeIndex === "clientPortal"
                    ? clientPortalItem.name
                    : model[activeIndex].name}
                </div>
                <div className='nav_parent_desc'>
                  {activeIndex === "clientPortal"
                    ? clientPortalItem.short_des
                    : model[activeIndex].short_des}
                </div>
                {activeIndex !== "clientPortal" && (
                  <SiteLink
                    to={model[activeIndex].link}
                    onClick={() => closeMenu()}
                    className='nav_parent_btn'
                  >
                    Explore
                  </SiteLink>
                )}
                <div className='nav_children'>
                  {activeIndex === "clientPortal"
                    ? "Our Client Portals"
                    : `Our ${model[activeIndex].name}`}
                </div>
                <div className='nav_children_list'>
                  {(activeIndex === "clientPortal"
                    ? clientPortalItem.children
                    : model[activeIndex].children
                  ).map((subItem, subIndex) => (
                    <SiteLink
                      key={subIndex}
                      to={subItem.link}
                      className='nav_children_link'
                      onClick={() => closeMenu()}
                    >
                      {subItem.name}
                    </SiteLink>
                  ))}
                </div>
              </div>
            )}
          </div>
          <div className='bottomLinks'>
            <div
              className='bottomLinkClientPortal'
              onClick={() => toggleClientPortal()}
            >
              <span>Client Portal</span>
            </div>
            <SiteLink to='https://dvm-accounting---morphett-vale.square.site' onClick={() => closeMenu()}>
              <span>Book Online</span>
            </SiteLink>
            <a href='tel:0883224110'>(08) 8322 4110</a>
          </div>
        </nav>
      </div>
    </div>
  );
}
